export default class CompanyMailTemplate {
    signature: string = '';
    copyAddress: string = '';

    static fromJson(dto: any): CompanyMailTemplate {
        const mailSettings = new CompanyMailTemplate();
        mailSettings.signature = dto.signature;
        mailSettings.copyAddress = dto.copyAddress;
        return mailSettings;
    }

    toJson(): any {
        return {
            signature: this.signature,
            copyAddress: this.copyAddress,
        };
    }
}