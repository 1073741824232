export default class CompanyMailSettings {
    protocol: string = '';
    host: string = '';
    port: number = 0;
    from: string = '';
    auth: boolean = true;
    username: string = '';
    password: string = '';
    ssl: boolean = false;
    startTls: boolean = false;

    static fromJson(dto: any): CompanyMailSettings {
        const mailSettings = new CompanyMailSettings();
        mailSettings.protocol = dto.protocol;
        mailSettings.host = dto.host;
        mailSettings.port = dto.port;
        mailSettings.from = dto.from;
        mailSettings.auth = dto.auth;
        mailSettings.username = dto.username;
        mailSettings.password = dto.password;
        mailSettings.ssl = dto.ssl;
        mailSettings.startTls = dto.startTls;
        return mailSettings;
    }

    toJson(): any {
        return {
            protocol: this.protocol,
            host: this.host,
            port: this.port,
            from: this.from,
            auth: this.auth,
            username: this.username,
            password: this.password,
            ssl: this.ssl,
            startTls: this.startTls,
        };
    }
}