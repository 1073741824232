import ProtocolElement from "@/model/protocol/elements/ProtocolElement";

export default class WorkItemProtocolElement extends ProtocolElement {
    static TYPE = 'work_item';
    type: string = WorkItemProtocolElement.TYPE;

    title: string = '';

    fromJson(dto: any): ProtocolElement {
        this.title = dto.title;
        return this;
    }
}