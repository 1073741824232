import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import store from "@/store";
import customerRoutes from "@/router/CustomerRoutes";
import protocolRoutes from "@/router/ProtocolRoutes";
import documentRoutes from "@/router/DocumentRoutes";
import userRoutes from "@/router/UserRoutes";
import settingsRoutes from "@/router/SettingsRoutes";
import companyRoutes from "@/router/CompanyRoutes";
import attachmentRoutes from "@/router/AttachementRoutes";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        name: 'Login',
        path: '/login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/',
        name: 'Home',
        component: () => import( '../views/Home.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/about',
        name: 'About',
        component: () => import( '../views/About.vue'),
        meta: {
            requiresAuth: true
        }
    },
    ...customerRoutes,
    ...userRoutes,
    ...companyRoutes,
    ...settingsRoutes,
    ...documentRoutes,
    ...attachmentRoutes,
    ...protocolRoutes,
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!store.getters.isLoggedIn && store.getters.hasToken) {
            await store.dispatch('useRefreshToken');
        }
        if (!store.getters.isLoggedIn) {
            next({name: 'Login'});
        }
    }
    next();
});

export default router;
