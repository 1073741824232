import {Action, Module, VuexModule} from "vuex-module-decorators";
import {http} from "@/store/index";
import Document from "@/model/Document";
import Page from "@/model/Page";
import DocumentUploadResult from "@/model/DocumentUploadResult";
import {DocumentSearchFilter} from "@/model/DocumentSearchFilter";

@Module
export default class DocumentModule extends VuexModule {
    @Action({rawError: true})
    async getAllDocumentsForPage({page, customerIdFilter}: {page: number, customerIdFilter: string}): Promise<Page> {
        const response = await http.get(`/document`, {
            params: {
                page: page,
                customerIdFilter: customerIdFilter
            },
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return Page.fromJson(response.data, (dto: any) => Document.fromJson(dto));
    }

    @Action({rawError: true})
    async getFilteredDocuments(documentFilter: DocumentSearchFilter): Promise<Page> {
        const response = await http.post(`/document/filter`, documentFilter, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return Page.fromJson(response.data, (dto: any) => Document.fromJson(dto));
    }

    @Action({rawError: true})
    async getDocument(id: number): Promise<Document> {
        const response = await http.get(`/document/${id}`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return Document.fromJson(response.data);
    }

    @Action({rawError: true})
    async sendDocumentByMail(id: number): Promise<DocumentUploadResult> {
        const response = await http.post(`/document/${id}`, null, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return DocumentUploadResult.fromJson(response.data);
    }

    @Action({rawError: true})
    async deleteDocument(id: number): Promise<void> {
        await http.delete(`/document/${id}`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
    }
}