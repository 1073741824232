import {RouteConfig} from "vue-router";

const documentRoutes: Array<RouteConfig> = [
    {
        path: '/documents',
        name: 'DocumentList',
        component: () => import('../views/documents/DocumentList.vue'),
        meta: {
            requiresAuth: true,
            fullscreen: true
        }
    },
];
export default documentRoutes;