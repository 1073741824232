import ProtocolSection from "@/model/protocol/ProtocolSection";

export interface ProtocolWithSections {
    sections: ProtocolSection[]
}

export default abstract class Protocol {
    abstract type: string;
    id: number = 0;

    name: string = '';

    templateFileName: string = '';

    abstract createDefault(): void;
    abstract fromJson(dto: any): Protocol;
}
