import ProtocolElement from "@/model/protocol/elements/ProtocolElement";
import ProtocolElementFactory from "@/model/protocol/elements/ProtocolElementFactory";
import {v4 as uuidv4} from "uuid";

export default class ProtocolSection {
    id = uuidv4();

    name: string = '';
    protocolElements: ProtocolElement[] = [];

    static fromJson(dto: any): ProtocolSection {
        const section = new ProtocolSection();
        section.name = dto.name;
        section.protocolElements = dto.protocolElements.map((obj: any) => ProtocolElementFactory.fromJson(obj));
        return section;
    }
}