import {RouteConfig} from "vue-router";

const companyRoutes: Array<RouteConfig> = [
    {
        path: '/companies',
        name: 'CompanyList',
        component: () => import('../views/company/CompanyList.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:id',
        name: 'CompanyEdit',
        component: () => import('../views/company/CompanyEdit.vue'),
        meta: {
            requiresAuth: true
        }
    },
];
export default companyRoutes;