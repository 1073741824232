export default class User {
    id: number = 0;
    username: string = '';
    password: string | null = '';

    role: string = '';

    firstName: string = '';
    lastName: string = '';
    function: string = '';

    telephone: string = '';
    telefax: string = '';
    mailAddress: string = '';

    companyMailAddresses: Map<string, string> = new Map();

    static fromJson(dto: any) {
        const user = new User();
        user.id = dto.id;
        user.username = dto.username;
        user.role = dto.role;
        user.firstName = dto.firstName;
        user.lastName = dto.lastName;
        user.function = dto.function;
        user.telephone = dto.telephone;
        user.telefax = dto.telefax;
        user.mailAddress = dto.mailAddress;
        if (dto.companyMailAddresses) {
            user.companyMailAddresses = new Map(Object.entries(dto.companyMailAddresses));
        }
        return user;
    }

    toJson(): any {
        return {
            id: this.id,
            username: this.username,
            password: this.password,
            role: this.role,
            firstName: this.firstName,
            lastName: this.lastName,
            function: this.function,
            telephone: this.telephone,
            telefax: this.telefax,
            mailAddress: this.mailAddress,
            companyMailAddresses: Object.fromEntries(this.companyMailAddresses)
        };
    }
}