import {Action, Module, VuexModule} from "vuex-module-decorators";
import Customer from "@/model/Customer";
import {http} from "@/store/index";
import {CustomerSearchFilter} from "@/model/CustomerSearchFilter";

@Module
export default class CustomerModule extends VuexModule {
    @Action({rawError: true})
    async getAllCustomers(): Promise<Customer[]> {
        const response = await http.get(`/customer`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return response.data.map((dto: any) => Customer.fromJson(dto));
    }

    @Action({rawError: true})
    async getFilteredCustomers(customerFilter: CustomerSearchFilter): Promise<Customer[]> {
        const response = await http.post(`/customer/filter`, customerFilter, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return response.data.map((dto: any) => Customer.fromJson(dto));
    }

    @Action({rawError: true})
    async getCustomer(id: number): Promise<Customer> {
        const response = await http.get(`/customer/${id}`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return Customer.fromJson(response.data);
    }

    @Action({rawError: true})
    async createCustomer(customer: Customer): Promise<Customer> {
        const response = await http.post(`/customer`, customer.toJson(), {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return Customer.fromJson(response.data);
    }

    @Action({rawError: true})
    async updateCustomer(customer: Customer): Promise<Customer> {
        const response = await http.put(`/customer/${customer.id}`, customer.toJson(), {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return Customer.fromJson(response.data);
    }

    @Action({rawError: true})
    async deleteCustomer(id: number): Promise<void> {
        await http.delete(`/customer/${id}`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
    }
}