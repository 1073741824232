export default class Customer {
    id: number = 0;

    customerId: number = 0;

    company: string = '';
    firstName: string = '';
    lastName: string = '';
    telephone: string = '';
    mailAddress: string = '';

    place: string = '';
    postcode: string = '';
    street: string = '';

    static fromJson(dto: any): Customer {
        const customer = new Customer();

        customer.id = dto.id;

        customer.customerId = dto.customerId;

        customer.company = dto.company;
        customer.firstName = dto.firstName;
        customer.lastName = dto.lastName;
        customer.telephone = dto.telephone;
        customer.mailAddress = dto.mailAddress;

        customer.place = dto.place;
        customer.postcode = dto.postcode;
        customer.street = dto.street;

        return customer;
    }

    toJson(): any {
        return {
            id: this.id,
            customerId: this.customerId,
            company: this.company,
            firstName: this.firstName,
            lastName: this.lastName,
            telephone: this.telephone,
            mailAddress: this.mailAddress,
            place: this.place,
            postcode: this.postcode,
            street: this.street
        };
    }
}