import {http} from '@/store';
import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {jwtDecode, JwtPayload} from "jwt-decode";
import PasswordChangeRequest from "@/model/user/PasswordChangeRequest";
import User from "@/model/user/User";
import AdminPasswordChangeRequest from "@/model/user/AdminPasswordChangeRequest";

export interface JwtPayloadScope {
    scope?: string[];
}

@Module
export default class AuthenticationModule extends VuexModule {
    private accessToken: string = '';
    private refreshToken: string = '';

    get token(): string {
        return this.accessToken;
    }

    get hasToken(): boolean {
        return this.accessToken.length !== 0;
    }

    get isLoggedIn(): boolean {
        if (!this.hasToken) {
            return false;
        }
        const jwt = jwtDecode<JwtPayload>(this.accessToken);
        return (jwt.exp ?? 0) > new Date().getTime() / 1000;
    }

    get username(): string | undefined | null {
        if (this.accessToken.length === 0) {
            return null;
        }
        const jwt = jwtDecode<JwtPayload>(this.accessToken);
        return jwt.sub;
    }

    get userRole(): string[] | undefined | null {
        if (this.accessToken.length === 0) {
            return null;
        }
        const jwt = jwtDecode<JwtPayloadScope>(this.accessToken);
        return jwt.scope;
    }

    @Mutation
    loginMutation({accessToken, refreshToken}: { accessToken: string, refreshToken: string }): void {
        this.accessToken = accessToken;
        this.refreshToken = refreshToken;
    }

    @Mutation
    logoutMutation(): void {
        this.accessToken = '';
        this.refreshToken = '';
    }

    @Action({rawError: true})
    async login({username, password}: { username: string, password: string }): Promise<void> {
        const response = await http.post('/token', {}, {
            auth: {
                username: username,
                password: password
            }
        });
        this.context.commit('loginMutation', {...response.data});
    }

    @Action({rawError: true})
    async useRefreshToken(): Promise<void> {
        try {
            const response = await http.post('/refresh_token', {
                jwtToken: this.accessToken,
                refreshToken: this.refreshToken
            });
            this.context.commit('loginMutation', {...response.data});
        } catch (error) {
            this.context.commit('logoutMutation');
        }
    }

    @Action({rawError: true})
    logout(): void {
        this.context.commit('logoutMutation');
    }

    @Action({rawError: true})
    async changePassword(passwordChangeRequest: PasswordChangeRequest): Promise<void> {
        await http.post(`/user/password`, passwordChangeRequest, {
            headers: {Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`}
        });
    }

    @Action({rawError: true})
    async getUserList(): Promise<User[]> {
        const response = await http.get(`/users`, {
            headers: {Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`}
        });
        return response.data.map((dto: any) => User.fromJson(dto));
    }

    @Action({rawError: true})
    async getUser(id: number): Promise<User> {
        const response = await http.get(`/users/${id}`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return User.fromJson(response.data);
    }

    @Action({rawError: true})
    async createUser(user: User): Promise<User> {
        const response = await http.post(`/users`, user, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return User.fromJson(response.data);
    }

    @Action({rawError: true})
    async updateUser(user: User): Promise<User> {
        const response = await http.put(`/users/${user.id}`, user.toJson(), {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return User.fromJson(response.data);
    }

    @Action({rawError: true})
    async changeUserPassword({id, changeRequest}:
                                 { id: number, changeRequest: AdminPasswordChangeRequest }): Promise<void> {
        await http.put(`/users/${id}/password`, changeRequest, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
    }

    @Action({rawError: true})
    async deleteUser(userId: number): Promise<void> {
        await http.delete(`/users/${userId}`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
    }
}
