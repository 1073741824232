import ProtocolElement from "@/model/protocol/elements/ProtocolElement";

export default class MaterialItemProtocolElement extends ProtocolElement {
    static TYPE = 'material_item';
    type: string = MaterialItemProtocolElement.TYPE;

    title: string = '';
    index: number = 0;

    fromJson(dto: any): ProtocolElement {
        this.title = dto.title;
        this.index = dto.index;
        return this;
    }
}