export default class UserName {
    id: number = 0;

    firstName: string = '';
    lastName: string = '';
    mailAddress: string = '';

    static fromJson(dto: any) {
        const user = new UserName();
        user.id = dto.id;
        user.firstName = dto.firstName;
        user.lastName = dto.lastName;
        user.mailAddress = dto.mailAddress;
        return user;
    }

    toJson(): any {
        return {
            id: this.id,
            firstName: this.firstName,
            lastName: this.lastName,
            mailAddress: this.mailAddress,
        };
    }
}