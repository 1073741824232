import {RouteConfig} from "vue-router";

const attachmentRoutes: Array<RouteConfig> = [
    {
        path: '/attachment',
        name: 'AttachmentList',
        component: () => import('../views/attachment/AttachmentList.vue'),
        meta: {
            requiresAuth: true
        }
    },
];
export default attachmentRoutes;