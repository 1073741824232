export default class Page {
    content: any[] = [];
    totalPages: number = 1;
    itemsPerPage: number = 0;

    static fromJson(dto: any, contentHandler: (obj: any) => any) {
        const page = new Page();
        page.content = dto.content.map(contentHandler);
        page.totalPages = dto.totalPages;
        page.itemsPerPage = dto.itemsPerPage;
        return page;
    }
}
