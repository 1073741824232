import {Action, Module, VuexModule} from "vuex-module-decorators";
import {http} from "@/store/index";
import CompanyMailTemplate from "@/model/CompanyMailTemplate";

@Module
export default class CompanyMailTemplateModule extends VuexModule {

    @Action({rawError: true})
    async getCompanyMailTemplate(id: number): Promise<CompanyMailTemplate> {
        const response = await http.get(`/company/${id}/template`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return CompanyMailTemplate.fromJson(response.data);
    }

    @Action({rawError: true})
    async updateCompanyMailTemplate({id, mailTemplate}: { id: number, mailTemplate: CompanyMailTemplate }): Promise<CompanyMailTemplate> {
        const response = await http.put(`/company/${id}/template`, mailTemplate.toJson(), {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return CompanyMailTemplate.fromJson(response.data);
    }
}