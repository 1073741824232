import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios";
import CustomerModule from "@/store/CustomerModule";
import CompanyModule from "@/store/CompanyModule";
import CompanyMailSettingsModule from "@/store/CompanyMailSettingsModule";
import CompanyMailTemplateModule from "@/store/CompanyMailTemplateModule";
import AuthenticationModule from "@/store/AuthenticationModule";
import SettingsModule from "@/store/SettingsModule";
import MailModule from "@/store/MailModule";
import DocumentModule from "@/store/DocumentModule";
import ProtocolModule from "@/store/ProtocolModule";
import AttachmentModule from "@/store/AttachmentModule";
import ResourceModule from "@/store/ResourceModule";
import VuexPersistence from 'vuex-persist';
import DocumentIdModule from "@/store/DocumentIdModule";

Vue.use(Vuex);

export const http = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
});

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

const store = new Vuex.Store({
  modules: {
    attachment: AttachmentModule,
    authentication: AuthenticationModule,
    company: CompanyModule,
    companyMailSettings: CompanyMailSettingsModule,
    companyMailTemplate: CompanyMailTemplateModule,
    customer: CustomerModule,
    document: DocumentModule,
    documentId: DocumentIdModule,
    mail: MailModule,
    protocol: ProtocolModule,
    resource: ResourceModule,
    settings: SettingsModule,
  },
  plugins: [vuexLocal.plugin],
});

export default store;

http.interceptors.response.use((response) => {
  return response;
}, async function (error) {
  const originalRequest = error.config;
  if (error.response.status === 401 && originalRequest.url !== '/token' && !originalRequest._retry) {
    originalRequest._retry = true;
    await store.dispatch('useRefreshToken');
    originalRequest.headers['Authorization'] = 'Bearer ' + store.getters.token;
    return http(originalRequest);
  }
  throw error;
});
