export default class SettingsData {
    name: string = '';
    value: string = '';
    present: boolean = false;

    static fromJson(dto: any): SettingsData {
        const data = new SettingsData();
        data.name = dto.name;
        data.value = dto.value;
        data.present = dto.present;
        return data;
    }

    toJson(): any {
        return {
            name: this.name,
            value: this.value,
            present: this.present
        };
    }
}