import {RouteConfig} from "vue-router";

const settingsRoutes: Array<RouteConfig> = [
    {
        path: '/settings/mail',
        name: 'MailSettings',
        component: () => import('../views/settings/MailSettings.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/settings/document-id',
        name: 'DocumentIdSettings',
        component: () => import('../views/settings/DocumentIdSettings.vue'),
        meta: {
            requiresAuth: true
        }
    },
];
export default settingsRoutes;