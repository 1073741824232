import {Action, Module, VuexModule} from "vuex-module-decorators";
import {http} from "@/store/index";
import CompanyMailSettings from "@/model/CompanyMailSettings";

@Module
export default class CompanyMailSettingsModule extends VuexModule {

    @Action({rawError: true})
    async getCompanyMailSettings(id: number): Promise<CompanyMailSettings> {
        const response = await http.get(`/company/${id}/mail`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return CompanyMailSettings.fromJson(response.data);
    }

    @Action({rawError: true})
    async sendCompanyTestMail(id: number): Promise<void> {
        await http.post(`/company/${id}/mail/test`, null, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
    }

    @Action({rawError: true})
    async updateCompanyMailSettings({id, mailSettings}: { id: number, mailSettings: CompanyMailSettings }): Promise<CompanyMailSettings> {
        const response = await http.put(`/company/${id}/mail`, mailSettings.toJson(), {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return CompanyMailSettings.fromJson(response.data);
    }
}