import UserName from "@/model/user/UserName";

export default class Document {
    id: number = 0;

    user: UserName | null = null;
    protocolId: string = '';
    createDateTime: Date | null = null;
    protocolType: string = '';
    customerId: string = '';
    customerMail: string = '';
    fileName: string = '';

    static fromJson(dto: any): Document {
        const document = new Document();
        document.id = dto.id;
        document.user = UserName.fromJson(dto.user);
        document.protocolId = dto.protocolId;
        document.createDateTime = dto.createDateTime;
        document.protocolType = dto.protocolType;
        document.customerId = dto.customerId;
        document.customerMail = dto.customerMail;
        document.fileName = dto.fileName;
        return document;
    }
}