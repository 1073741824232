
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class App extends Vue {
  onLogout(): void {
    this.$store.dispatch('logout');
    this.$router.push({name: 'Login'});
  }

  get isAdmin(): boolean {
    return this.$store.getters.userRole?.includes('ADMIN');
  }
}
