import TestProtocol from "@/model/protocol/TestProtocol";
import Protocol from "@/model/protocol/Protocol";
import WorkProtocol from "@/model/protocol/WorkProtocol";

type Type<T> = new(...args: any[]) => T;

export class ProtocolType {
    type: string;
    clazz: Type<Protocol>;
    localization: string;

    constructor(type: string, clazz: Type<Protocol>, localization: string) {
        this.type = type;
        this.clazz = clazz;
        this.localization = localization;
    }
}

export default class ProtocolFactory {

    private static protocolTypes: ProtocolType[] = [
        new ProtocolType(TestProtocol.TYPE, TestProtocol, 'Prüfprotokoll'),
        new ProtocolType(WorkProtocol.TYPE, WorkProtocol, 'Arbeitsprotokoll')
    ];

    private static getProtocolType(key: string): ProtocolType | undefined {
        return this.protocolTypes.find(entry => entry.type === key);
    }

    static get getProtocolTypes(): ProtocolType[] {
        return this.protocolTypes;
    }

    static createProtocol(name: string, type: string): Protocol | undefined {
        const protocolClass = this.getProtocolType(type)?.clazz;
        if (protocolClass != undefined) {
            const protocol = new protocolClass();
            protocol.name = name;
            protocol.createDefault();
            return protocol;
        }
        console.log(`Protocol of type ${type} is not supported`);
        return undefined;
    }

    static fromJson(dto: any): Protocol | undefined {
        const protocolClass = this.getProtocolType(dto.type as string)?.clazz;
        if (protocolClass != undefined) {
            let protocol: Protocol = new protocolClass();

            protocol.id = dto.id;
            protocol.name = dto.name;
            protocol.templateFileName = dto.templateFileName;
            protocol = protocol.fromJson(dto);
            return protocol;
        } else {
            console.log(`Protocol of type ${dto.type} is not supported`);
            return undefined;
        }
    }

    static getLocalization(protocol: Protocol): string | undefined {
        return this.getProtocolType(protocol.type)?.localization;
    }
}