import UserName from "@/model/user/UserName";

export default class Attachment {
    id: number = 0;

    user: UserName | null = null;
    fileName: string = '';
    title: string = '';
    createDateTime: Date | null = null;
    used: boolean = false;

    static fromJson(dto: any): Attachment {
        const attachment = new Attachment();
        attachment.id = dto.id;
        attachment.user = UserName.fromJson(dto.user);
        attachment.fileName = dto.fileName;
        attachment.title = dto.title;
        attachment.createDateTime = dto.createDateTime;
        attachment.used = dto.used;
        return attachment;
    }

    toJson(): any {
        return {
            id: this.id,
            user: {
                id: this.user?.id
            },
            fileName: this.fileName,
            title: this.title,
            createDateTime: this.createDateTime,
            used: this.used,
        };
    }
}