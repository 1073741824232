import ProtocolElement from "@/model/protocol/elements/ProtocolElement";

export default class AttachmentProtocolElement extends ProtocolElement {
    static TYPE = 'attachment';
    type: string = AttachmentProtocolElement.TYPE;

    title: string = '';

    signed: boolean = false;

    fromJson(dto: any): ProtocolElement {
        this.title = dto.title;
        this.signed = dto.signed;
        return this;
    }
}