import ProtocolElement from "@/model/protocol/elements/ProtocolElement";
import {v4 as uuidv4} from "uuid";

export class Option {
    id = uuidv4();
    value: string = '';
}

export default class OptionSelectProtocolElement extends ProtocolElement {
    static TYPE = 'option_select';
    type: string = OptionSelectProtocolElement.TYPE;

    title: string = '';
    options: Option[] = [];

    notesField: boolean = false;
    bold: boolean = false;

    fromJson(dto: any): ProtocolElement {
        this.title = dto.title;
        this.options = dto.options.map((value: any) => {
            const option = new Option();
            option.value = value.value;
            return option;
        });

        this.notesField = dto.notesField;
        this.bold = dto.bold;
        return this;
    }
}