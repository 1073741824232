import ProtocolElement from "@/model/protocol/elements/ProtocolElement";

export default class TextInputProtocolElement extends ProtocolElement {
    static TYPE = 'text_input';
    type: string = TextInputProtocolElement.TYPE;

    title: string = '';

    multiline: boolean = false;
    bold: boolean = false;

    fromJson(dto: any): ProtocolElement {
        this.title = dto.title;

        this.multiline = dto.multiline;
        this.bold = dto.bold;
        return this;
    }
}