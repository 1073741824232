import Protocol, {ProtocolWithSections} from "@/model/protocol/Protocol";
import ProtocolSection from "@/model/protocol/ProtocolSection";

export default class TestProtocol extends Protocol implements ProtocolWithSections {
    static TYPE = 'test_protocol';
    type: string = TestProtocol.TYPE;

    sections: ProtocolSection[] = [];

    createDefault(): void {
        this.sections = [];
    }

    fromJson(dto: any): Protocol {
        this.sections = dto.sections.map((obj: any) => ProtocolSection.fromJson(obj));
        return this;
    }
}