export default class Company {
    id: number = 0;
    name: string = '';
    fileName: string = '';
    defaultCompany: boolean = false;

    static fromJson(dto: any): Company {
        const company = new Company();
        company.id = dto.id;
        company.name = dto.name;
        company.fileName = dto.fileName;
        company.defaultCompany = dto.defaultCompany;
        return company;
    }

    toJson(): any {
        return {
            id: this.id,
            name: this.name,
            fileName: this.fileName,
            defaultCompany: this.defaultCompany
        };
    }
}