import {Action, Module, VuexModule} from "vuex-module-decorators";
import {http} from "@/store/index";

@Module
export default class ResourceModule extends VuexModule {
    @Action({rawError: true})
    async downloadResource(fileName: string): Promise<any> {
        const response = await http.get(fileName, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
            },
            responseType: 'blob'
        });
        return response.data;
    }
}