import {v4 as uuidv4} from "uuid";

export default abstract class ProtocolElement {
    abstract type: string

    id = uuidv4();
    identifier: string = '';
    editableWhenSigned: boolean = false;

    abstract fromJson(dto: any): ProtocolElement;
}