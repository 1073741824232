import {Action, Module, VuexModule} from "vuex-module-decorators";
import {http} from "@/store/index";
import Protocol from "@/model/protocol/Protocol";
import ProtocolFactory from "@/model/protocol/ProtocolFactory";
import {readAsDataURL} from "@/utils/AsyncFileReader";

@Module
export default class ProtocolModule extends VuexModule {
    @Action({rawError: true})
    async getProtocols(): Promise<Protocol[]> {
        const response = await http.get(`/protocol`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return response.data.map((dto: any) => ProtocolFactory.fromJson(dto));
    }

    @Action({rawError: true})
    async getProtocol(id: number): Promise<Protocol | undefined> {
        const response = await http.get(`/protocol/${id}`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return ProtocolFactory.fromJson(response.data);
    }

    @Action({rawError: true})
    async createProtocol(protocol: Protocol): Promise<Protocol | undefined> {
        const response = await http.post(`/protocol`, protocol, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return ProtocolFactory.fromJson(response.data);
    }

    @Action({rawError: true})
    async updateProtocol(protocol: Protocol): Promise<Protocol | undefined> {
        const response = await http.put(`/protocol/${protocol.id}`, protocol, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return ProtocolFactory.fromJson(response.data);
    }

    @Action({rawError: true})
    async updateProtocolTemplate({id, file}: { id: number, file: File }): Promise<Protocol | undefined> {
        const fileType = file.type;
        const fileData = await readAsDataURL(file);
        const response = await http.put(`/protocol/${id}/template`, fileData, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-Type': fileType,
            }
        });
        return ProtocolFactory.fromJson(response.data);
    }

    @Action({rawError: true})
    async deleteProtocol(id: number): Promise<void> {
        await http.delete(`/protocol/${id}`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
    }
}