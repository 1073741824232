import ProtocolElement from "@/model/protocol/elements/ProtocolElement";

export default class CountInputProtocolElement extends ProtocolElement {
    static TYPE = 'count_input';
    type: string = CountInputProtocolElement.TYPE;

    title: string = '';

    fromJson(dto: any): ProtocolElement {
        this.title = dto.title;
        return this;
    }
}