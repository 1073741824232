import {RouteConfig} from "vue-router";

const userRoutes: Array<RouteConfig> = [
    {
        name: 'PasswordChange',
        path: '/user/password',
        component: () => import('../views/user/PasswordChange.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'UserList',
        path: '/users',
        component: () => import('../views/user/UserList.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/users/edit/:id',
        name: 'UserEdit',
        component: () => import('../views/user/UserEdit.vue'),
        meta: {
            requiresAuth: true
        }
    },
];
export default userRoutes;