import {RouteConfig} from "vue-router";

const protocolRoutes: Array<RouteConfig> = [
    {
        path: '/protocol',
        name: 'ProtocolList',
        component: () => import('../views/protocol/ProtocolList.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/protocol/:id',
        name: 'ProtocolEdit',
        component: () => import('../views/protocol/ProtocolEdit.vue'),
        meta: {
            requiresAuth: true
        }
    }
];
export default protocolRoutes;