import {Action, Module, VuexModule} from "vuex-module-decorators";
import {http} from "@/store/index";

@Module
export default class DocumentIdModule extends VuexModule {
    @Action({rawError: true})
    async peekDocumentId(): Promise<number> {
        const response = await http.get(`/document/id/peek`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return response.data.id;
    }

    @Action({rawError: true})
    async updateDocumentId(nextId: number): Promise<void> {
        await http.post(`/document/id`, {
            id: nextId
        }, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
    }
}