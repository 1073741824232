import TextInputProtocolElement from "@/model/protocol/elements/TextInputProtocolElement";
import OptionSelectProtocolElement from "@/model/protocol/elements/OptionSelectProtocolElement";
import CountInputProtocolElement from "@/model/protocol/elements/CountInputProtocolElement";
import LabelProtocolElement from "@/model/protocol/elements/LabelProtocolElement";
import ProtocolElement from "@/model/protocol/elements/ProtocolElement";
import AttachmentProtocolElement from "@/model/protocol/elements/AttachmentProtocolElement";
import WorkItemProtocolElement from "@/model/protocol/elements/WorkItemProtocolElement";
import MaterialItemProtocolElement from "@/model/protocol/elements/MaterialItemProtocolElement";

type Type<T> = new(...args: any[]) => T;

export class ProtocolElementType {
    type: string;
    clazz: Type<ProtocolElement>;
    localization: string;

    constructor(type: string, clazz: Type<ProtocolElement>, localization: string) {
        this.type = type;
        this.clazz = clazz;
        this.localization = localization;
    }
}

export default class ProtocolElementFactory {
    private static protocolElementTypes: ProtocolElementType[] = [
        new ProtocolElementType(TextInputProtocolElement.TYPE, TextInputProtocolElement, 'Textfeld'),
        new ProtocolElementType(OptionSelectProtocolElement.TYPE, OptionSelectProtocolElement, 'Auswahlfeld'),
        new ProtocolElementType(CountInputProtocolElement.TYPE, CountInputProtocolElement, 'Stückzahl Eingabe'),
        new ProtocolElementType(LabelProtocolElement.TYPE, LabelProtocolElement, 'Beschriftung'),
        new ProtocolElementType(AttachmentProtocolElement.TYPE, AttachmentProtocolElement, 'Anhang'),
        new ProtocolElementType(WorkItemProtocolElement.TYPE, WorkItemProtocolElement, 'Arbeits- und Kfz-Abrechnung'),
        new ProtocolElementType(MaterialItemProtocolElement.TYPE, MaterialItemProtocolElement, 'Materialabrechnung'),
    ];

    private static getProtocolElementType(key: string): ProtocolElementType | undefined {
        return ProtocolElementFactory.protocolElementTypes.find(entry => entry.type === key);
    }

    static get getProtocolElementTypes(): ProtocolElementType[] {
        return ProtocolElementFactory.protocolElementTypes;
    }

    static createProtocolElement(type: string): ProtocolElement | undefined {
        const protocolElementClass = ProtocolElementFactory.getProtocolElementType(type)?.clazz;
        if (protocolElementClass != undefined) {
            return new protocolElementClass();
        }
        console.log(`Protocol of type ${type} is not supported`);
        return undefined;
    }

    static fromJson(dto: any): ProtocolElement | undefined {
        const protocolElementClass = ProtocolElementFactory.getProtocolElementType(dto.type as string)?.clazz;
        if (protocolElementClass != undefined) {
            let protocolElement: ProtocolElement = new protocolElementClass();

            protocolElement.identifier = dto.identifier;
            protocolElement.editableWhenSigned = dto.editableWhenSigned;
            protocolElement = protocolElement.fromJson(dto);
            return protocolElement;
        } else {
            console.log(`ProtocolElement of type ${dto.type} is not supported`);
            return undefined;
        }
    }

    static getLocalization(protocolElement: ProtocolElement): string | undefined {
        return ProtocolElementFactory.getProtocolElementType(protocolElement.type)?.localization;
    }
}