import {Action, Module, VuexModule} from "vuex-module-decorators";
import {http} from "@/store/index";
import Company from "@/model/Company";
import {readAsDataURL} from "@/utils/AsyncFileReader";

@Module
export default class CompanyModule extends VuexModule {
    @Action({rawError: true})
    async getAllCompanies(): Promise<Company[]> {
        const response = await http.get(`/company`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return response.data.map((dto: any) => Company.fromJson(dto));
    }

    @Action({rawError: true})
    async getCompany(id: number): Promise<Company> {
        const response = await http.get(`/company/${id}`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return Company.fromJson(response.data);
    }

    @Action({rawError: true})
    async createCompany(company: Company): Promise<Company> {
        const response = await http.post(`/company`, company.toJson(), {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return Company.fromJson(response.data);
    }

    @Action({rawError: true})
    async renameCompany({id, name}: { id: number, name: string }): Promise<Company> {
        const response = await http.put(`/company/${id}`, {
            name: name
        }, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return Company.fromJson(response.data);
    }

    @Action({rawError: true})
    async updateCompanyLogo({id, file}: { id: number, file: File }): Promise<Company> {
        const fileType = file.type;
        const fileData = await readAsDataURL(file);
        const response = await http.put(`/company/${id}/logo`, fileData, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-Type': fileType,
            }
        });
        return Company.fromJson(response.data);
    }

    @Action({rawError: true})
    async markAsDefault(id: number): Promise<void> {
        await http.post(`/company/default`, {
            defaultCompanyId: id
        }, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
    }

    @Action({rawError: true})
    async deleteCompany(id: number): Promise<void> {
        await http.delete(`/company/${id}`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
    }
}