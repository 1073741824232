import {RouteConfig} from "vue-router";

const customerRoutes: Array<RouteConfig> = [
    {
        path: '/customer',
        name: 'CustomerList',
        component: () => import( '../views/customer/CustomerList.vue'),
        meta: {
            requiresAuth: true,
            fullscreen: true
        }
    },
    {
        path: '/customer/create',
        name: 'CustomerCreate',
        component: () => import( '../views/customer/CustomerCreate.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/customer/edit/:id',
        name: 'CustomerEdit',
        component: () => import('../views/customer/CustomerEdit.vue'),
        meta: {
            requiresAuth: true
        }
    },
];
export default customerRoutes;