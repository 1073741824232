import {Action, Module, VuexModule} from "vuex-module-decorators";
import {http} from "@/store/index";

@Module
export default class MailModule extends VuexModule {
    @Action({rawError: true})
    async sendTestMail(): Promise<void> {
        await http.post(`/system/mail/test`, null, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
    }
}