import ProtocolElement from "@/model/protocol/elements/ProtocolElement";

export default class LabelProtocolElement extends ProtocolElement {
    static TYPE = 'label';
    type: string = LabelProtocolElement.TYPE;

    label: string = '';

    bold: boolean = false;

    fromJson(dto: any): ProtocolElement {
        this.label = dto.label;
        this.bold = dto.bold;
        return this;
    }
}