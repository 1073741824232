export default class DocumentUploadResult {

    success: boolean | null = null;
    reason: string = '';

    static fromJson(dto: any): DocumentUploadResult {
        const result = new DocumentUploadResult();
        result.success = dto.success;
        result.reason = dto.reason;
        return result;
    }
}