import {Action, Module, VuexModule} from "vuex-module-decorators";
import {http} from "@/store/index";
import Attachment from "@/model/Attachment";
import {readAsDataURL} from "@/utils/AsyncFileReader";

@Module
export default class AttachmentModule extends VuexModule {
    @Action({rawError: true})
    async getAllAttachments(): Promise<Attachment[]> {
        const response = await http.get(`/attachment?all=true`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return response.data.map((dto: any) => Attachment.fromJson(dto));
    }

    @Action({rawError: true})
    async getAttachment(id: number): Promise<Attachment> {
        const response = await http.get(`/attachment/${id}`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return Attachment.fromJson(response.data);
    }

    @Action({rawError: true})
    async createAttachment(attachment: Attachment): Promise<Attachment> {
        const response = await http.post(`/attachment`, attachment.toJson(), {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return Attachment.fromJson(response.data);
    }

    @Action({rawError: true})
    async downloadAttachment(document: Attachment): Promise<any> {
        const response = await http.get(document.fileName, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
            },
            responseType: 'blob'
        });
        return response.data;
    }

    @Action({rawError: true})
    async uploadAttachment({id, file}: { id: number, file: File }): Promise<Attachment> {
        const fileType = file.type;
        const fileData = await readAsDataURL(file);
        const response = await http.put(`/attachment/${id}`, fileData, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-Type': fileType,
            }
        });
        return Attachment.fromJson(response.data);
    }

    @Action({rawError: true})
    async deleteAttachment(id: number): Promise<void> {
        await http.delete(`/attachment/${id}`, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
    }
}