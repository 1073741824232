import {Action, Module, VuexModule} from "vuex-module-decorators";
import SettingsData from "@/model/SettingsData";
import {http} from "@/store/index";
import {parseParam} from "@/utils/ParamUtils";
import {SettingsType} from "@/model/SettingsTypes";

@Module
export default class SettingsModule extends VuexModule {
    @Action({rawError: true})
    async getSettings(keys: SettingsType[]): Promise<SettingsData[]> {
        const response = await http.get(`/system/settings`, {
            params: {
                name: keys.map(element => element.name)
            },
            paramsSerializer: parseParam,
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
        return response.data.map((dto: any) => SettingsData.fromJson(dto));
    }

    @Action({rawError: true})
    async updateSettings(data: SettingsData[]): Promise<void> {
        await http.post(`/system/settings`, data, {
            headers: {
                Authorization: `Bearer ${this.context.rootState.authentication.accessToken}`,
                'Content-type': 'application/json',
            }
        });
    }
}